// header toggle class on scroll
(() => {
  const headerEl = document.querySelector('.header');
  const fixedClass = 'header--fixed';

  const toggleHeader = () => {
    if (window.pageYOffset > headerEl.offsetHeight) {
      headerEl.classList.add(fixedClass);
    } else {
      headerEl.classList.remove(fixedClass);
    }
  };

  toggleHeader();
  window.addEventListener('scroll', toggleHeader);
})();

// select active item in menu
(() => {
  const $links = document.querySelectorAll('.menu__link');

  if ($links.length) {
    const activeClass = 'menu__link--active';
    const parsePageName = (string) => string.split('/')[1].split(".")[0].toLowerCase();

    for (let i = 0; i < $links.length; i++) {
      if (parsePageName($links[i].getAttribute('href')) === parsePageName(window.location.pathname)) {
        $links[i].classList.add(activeClass);
      }
    }
  }
})();

// open popup
(() => {
  const $callPopup = document.querySelectorAll('.js_open_popup');

  if ($callPopup) {
    for (let i = 0; i < $callPopup.length; i++) {
      $callPopup[i].addEventListener('click', function (e) {
        e.preventDefault();
        const popupBody = this.getAttribute('data-popup-head');
        if (popupBody) {
          lm_popup.init();
          lm_popup.addContent(`[data-popup-body="${popupBody}"]`);
          lm_popup.show();
        }

        return false;
      });
    }
  }
})();
